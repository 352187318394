<template>
  <div class="card" :style="card.shadowColorCard">
    <div class="icon">
      <img :src="require('../../assets/images/' + card.icon + '')" alt="icon" />
    </div>
    <div class="btn" :style="card.btnStyle">
      <p>{{ card.title }}</p>
      <router-link :to="{ name: card.namePage }">
        <img src="https://img.icons8.com/windows/32/000000/info.png" />
        {{ $t("refugeesMenu.namePage") }}</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    card: Object,
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 255px;
  border: none;
  margin: 15px 50px;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.4s;
}
.icon {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}
.icon img {
  width: 100px;
  padding: 10px;
  transform: translateY(35px);
  transition: 0.4s;
}
.btn {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: translateY(65px);
  transition: 0.4s;
}
.btn p{
  position: relative;
  margin: 25px 0;
  font-weight: bolder;
  transition: 0.4s;
}
.btn a {
  background: #fff;
  color: #000;
  padding: 5px 10px;
  margin-bottom: 20px;
  font-weight: 500;
  border-radius: 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease 0.2s;
}
.card:hover{
  background: #fff;
}
.card:hover .btn{
  transform: translateY(0);
}
.card:hover .icon{
  background: #fff;
}
.card:hover .icon img{
  transform: translateY(0);
  opacity: 1;
}
.btn a:hover {
  text-decoration: underline;
  transition: ease 0.2s;
}
#arrow-right {
  width: 35px;
}
@media (max-width: 450px) {
  .card {
    width: 100%;
    margin: 15px 0;
  }
  .btn {
    transform: translateY(0);
  }
  .btn p{
    margin: 10px 0;
  }
  .icon img{
    transform: translateY(0);
  }
  
}
</style>