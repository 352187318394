<template>
  <div class="refugees-menu">
    <Header />
    <Policy />
    <div class="title">
      <div class="text">
        <h2>{{ $t("refugeesMenu.h2") }}</h2>
        <p>{{ $t("refugeesMenu.p") }}</p>
      </div>
      <img src="@/assets/images/img7.jpg" alt="" />
    </div>
    <div class="cards-container">
      <div class="cards">
        <RefugeesMenuTemplate :card="abrigos" />
        <RefugeesMenuTemplate :card="comunidade" />
        <RefugeesMenuTemplate :card="documentos" />
        <RefugeesMenuTemplate :card="emergencia" />
        <RefugeesMenuTemplate :card="empregos" />
        <RefugeesMenuTemplate :card="educacao" />
        <RefugeesMenuTemplate :card="juridico" />
        <RefugeesMenuTemplate :card="restaurantes" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import RefugeesMenuTemplate from "@/components/templates/RefugeesMenuTemplate";
import Footer from "@/components/Footer";
import Policy from "@/components/Policy";
export default {
  data() {
    return {
      // custom properties to the cards
      documentos: {
        icon: "documentos.gif",
        title: this.$t("refugeesMenu.document"),
        btnStyle: "background: #2c82c9; color: white",
        namePage: "documents-PT",
        shadowColorCard: "box-shadow: 0 0 20px #2c82c9;",
      },
      empregos: {
        icon: "empregos.png",
        title: this.$t("refugeesMenu.jobs"),
        btnStyle: "background: #2cc990; color: white",
        namePage: "jobs-PT",
        shadowColorCard: "box-shadow: 0 0 20px #2cc990; ",
      },
      comunidade: {
        icon: "comunidade.gif",
        title: this.$t("refugeesMenu.community"),
        btnStyle: "background: #ff9b13; color: white",
        namePage: "community-PT",
        shadowColorCard: "box-shadow: 0 0 20px #ff9b13;",
      },
      juridico: {
        icon: "juridico.gif",
        title: this.$t("refugeesMenu.legal"),
        btnStyle: "background: #8d26d6; color: white",
        namePage: "legal-PT",
        shadowColorCard: "box-shadow: 0 0 20px #8d26d6;",
      },
      abrigos: {
        icon: "abrigos.png",
        title: this.$t("refugeesMenu.shelters"),
        btnStyle: "background: #39416f; color: white",
        namePage: "shelters-PT",
        shadowColorCard: "box-shadow: 0 0 20px #39416f;",
      },
      emergencia: {
        icon: "emergencia.png",
        title: this.$t("refugeesMenu.emergency"),
        btnStyle: "background: red; color: white",
        namePage: "emergency-PT",
        shadowColorCard: "box-shadow: 0 0 20px red;",
      },
      restaurantes: {
        icon: "restaurantes.png",
        title: this.$t("refugeesMenu.restaurants"),
        btnStyle: "background: #92f22a; color: white",
        namePage: "restaurant-PT",
        shadowColorCard: "box-shadow: 0 0 20px #92f22a;",
      },
      educacao: {
        icon: "educação.gif",
        title: this.$t("refugeesMenu.education"),
        btnStyle: "background: #fc00ff; color: white",
        namePage: "education-PT",
        shadowColorCard: "box-shadow: 0 0 20px #fc00ff;",
      },
    };
  },
  components: {
    Header,
    RefugeesMenuTemplate,
    Footer,
    Policy,
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.refugees-menu {
  width: 100%;
  height: 100vh;
}
.title {
  padding: 0 120px;
  background: linear-gradient(to left, #ffb75e, #ed8f03);
  color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title img {
  width: 300px;
}
.title h2 {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
}
.cards-container{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px 80px;
}
.cards {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}
@media (max-width: 1200px) {
  .title {
    padding: 0;
  }
  .title img {
    display: none;
  }
  .text h2 {
    padding: 20px 20px 0 20px;
  }
  .text p {
    padding: 10px 20px;
  }
}
@media(max-width: 850px){
  .cards-container{
    padding: 20px;
  }
}
</style>